body {
  margin: 0;
}

.button {
  border-radius: 100px;
}

a, a:visited {
  color: #237cb3;
}

a:hover, a:visited:hover {
  color: #50a7dc;
}

.section {
  text-align: center;
  padding: 8rem 0 7rem;
}

.section-heading, .section-description {
  margin-bottom: 1.2rem;
}

.has-no-margin {
  margin: 0;
}

.values {
  color: #fff;
  background-image: url("values-bg.8e6c8ca4.jpg");
  background-size: cover;
  padding-bottom: 5rem;
}

.value-multiplier {
  color: #11dfc7;
  margin-bottom: .5rem;
}

.value-logo {
  width: 55px;
  margin-bottom: .5rem;
}

.value-logo.is-medium {
  width: 100px;
}

.value-logo.is-large {
  width: 150px;
}

.value-heading {
  margin-bottom: .3rem;
  font-weight: normal;
}

.value-description, .values .value-heading, .values .section-description {
  font-weight: 500;
}

.values .button, .values .button:visited {
  background-color: #227cb4;
  border-color: #196493;
  font-weight: bold;
  box-shadow: 0 0 10px #ffffff26;
}

.values .button:hover, .values .button:visited:hover {
  background-color: #4ca5dd;
  border-color: #227cb4;
}

.contact {
  border-bottom: 1px solid #ddd;
}

@media (max-width: 650px) {
  .four.columns.value.earlybreak {
    width: 100%;
    margin-left: 0%;
  }
}

@media (min-width: 750px) {
  .hero {
    height: 190px;
  }

  .hero-heading {
    font-size: 2.6rem;
  }

  .section-description {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .phone {
    max-height: 510px;
    top: -14rem;
    right: 5rem;
  }

  .phone + .phone {
    max-height: 472px;
    top: -12rem;
  }

  .categories {
    padding: 15rem 0 8rem;
  }
}

@media (min-width: 1000px) {
  .hero-heading {
    font-size: 3rem;
  }

  .phone {
    max-height: 615px;
    top: -16rem;
  }

  .phone + .phone {
    max-height: 570px;
    top: -14rem;
  }
}

/*# sourceMappingURL=index.fd247e69.css.map */
