body {
    margin: 0;
}

/* Shared
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button {
  border-radius: 100px;
}

a, a:visited {
  color: #237CB3;
}

a:hover, a:visited:hover {
  color: #50A7DC;
}

/* Sections
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.section {
  padding: 8rem 0 7rem;
  text-align: center;
}
.section-heading,
.section-description {
  margin-bottom: 1.2rem;
}

.has-no-margin {
  margin: 0;
}

/* Values
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.values {
  background-image: url('../images/values-bg.jpg');
  background-size: cover;
  color: #fff;
  padding-bottom: 5rem;
}
.value-multiplier {
  margin-bottom: .5rem;
  color: #11DFC7;
}

.value-logo {
  width: 55px;
  margin-bottom: .5rem;
}

.value-logo.is-medium {
  width: 100px;
}

.value-logo.is-large {
  width: 150px;
}

.value-heading {
  font-weight: normal;
  margin-bottom: .3rem;
}
.value-description, .values .value-heading, .values .section-description {
  /* Since the font is so lightweight, it is harder to read on lower contrasts */
  font-weight: 500;
}
.values .button, .values .button:visited {
  font-weight: bold;
  background-color: #227CB4;
  border-color: #196493;
  box-shadow: rgba(255, 255, 255, 0.15) 0 0 10px;
}
.values .button:hover, .values .button:visited:hover {
  background-color: #4CA5DD;
  border-color: #227CB4;
}


/* Contact
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.contact {
  border-bottom: 1px solid #ddd;
}

@media (max-width: 650px) {
  .four.columns.value.earlybreak {
    width: 100%;
    margin-left: 0%;
  }
}

/* Bigger than 750 */
@media (min-width: 750px) {
  .hero {
    height: 190px;
  }
  .hero-heading {
    font-size: 2.6rem;
  }
  .section-description {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .phone {
    top: -14rem;
    right: 5rem;
    max-height: 510px;
  }
  .phone + .phone {
    top: -12rem;
    max-height: 472px;
  }
  .categories {
    padding: 15rem 0 8rem;
  }
}

/* Bigger than 1000 */
@media (min-width: 1000px) {
  .hero-heading {
    font-size: 3.0rem;
  }
  .phone {
    top: -16rem;
    max-height: 615px;
  }
  .phone + .phone {
    top: -14rem;
    max-height: 570px;
  }
}
